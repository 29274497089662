import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { init, initData } from "@telegram-apps/sdk-react";

import Navigation from "./components/navigation/navigation.component";
import { GlobalStyle } from "./global.styles";

import AppLoyout from "./routes/apployout";

function App() {
  // const [searchParams, setSearchParams] = useSearchParams();
  
  useEffect(() => {
    // const userId = searchParams.get("user_id");
    // if (userId) localStorage.setItem("userId", userId);

    const userId = initData.user()?.id;
    if (userId) localStorage.setItem("userId", userId.toString());
  }, []);

  return (
    <div>
      <GlobalStyle />
      <AppLoyout />
      <Navigation />
    </div>
  );
}

export default App;
