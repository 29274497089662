import React, { Suspense, lazy, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { AppBackground, BGLight } from "./apployout.style";
import { generateRandomObjects, Position } from "../utils/random-light-pos";
import LoadingPage from "./general/loading-page.comonent";

const PartnerLoyoutLazy = lazy(() => import("./partner-page/partner-loyout"));
const UserLoyoutLazy = lazy(() => import("./user-page/userloyout"));
const AdminLoyoutLazy = lazy(() => import("./admin-page/adminloyout"));

function AppLoyout() {
  const [firstLightCoordinate, setFirstLightCoordinate] = useState<any>();
  const [secondLightCoordinate, setSecondLightCoordinate] = useState<any>();
  const location = useLocation();

  useEffect(() => {
    const savedPosition = localStorage.getItem("light");
    if (savedPosition) {
      const savedPositionObj = JSON.parse(savedPosition);
      setFirstLightCoordinate(savedPositionObj?.obj1);
      setSecondLightCoordinate(savedPositionObj?.obj2);
    }
    const randomPosition = generateRandomObjects();
    setFirstLightCoordinate(randomPosition.obj1);
    setSecondLightCoordinate(randomPosition.obj2);
    localStorage.setItem("light", JSON.stringify(randomPosition));
  }, [location.pathname]);

  return (
    <>
      <Routes>
        <Route
          path="/*"
          element={
            <Suspense fallback={<LoadingPage />}>
              <UserLoyoutLazy />
              {/* <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%'}}>
                  <h1>
                  В доработке
                  </h1>
              </div> */}
            </Suspense>
          }
        />
        <Route
          path="/admin/*"
          element={
            <Suspense fallback={<LoadingPage />}>
              <AdminLoyoutLazy />
            </Suspense>
          }
        />
        <Route
          path="/partner/*"
          element={
            <Suspense fallback={<LoadingPage />}>
              <PartnerLoyoutLazy />
            </Suspense>
          }
        />
        <Route
          path="/loading"
          element={
            <Suspense fallback={<LoadingPage />}>
              <LoadingPage />
            </Suspense>
          }
        />
      </Routes>
      <AppBackground>
        <BGLight style={{ ...firstLightCoordinate }} />
        <BGLight style={{ ...secondLightCoordinate }} />
      </AppBackground>
    </>
  );
}

export default AppLoyout;
