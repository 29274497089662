import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { init } from "./init";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

try {
  init();

  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  );
} catch (e) {
  root.render(
    <h1>
      Error
      <br /> Unsuported open outside telegram
    </h1>
  );
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
